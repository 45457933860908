// @flow

import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { typedObjectEntries } from "./flowUtils";

export const api: any = axios.create({
  baseURL: "https://api.colormotive.com",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

type TRoutes = {
  JOBS: string,
  GET_DATA: string,
  ADD_ADJS: string,
};

export const ApiCallMethod: string = "get" || "post";

type TError = Error & {
  response?: {
    data: any,
  }
};
type TResponse = Response & { data: any };

type TApiResponse = {
  executeApiCall: Function,
  response: ?TResponse,
  error: ?TError,
  isLoading: boolean,
  status: ?number,
};

export type TPostData = { [string]: string | number };

type TCMApiCallJob = { url: string };

export const Routes: TRoutes = {
  JOBS: "/jobs",
  GET_DATA: "/get_data",
  GET_ADJS: "/get_adjs",
  ADD_ADJS: "/add_adjs",
};

// the path api expects, to get these data back based on a job id
type TCMApiCallJobData = {
  ADJS: "adjs", // adjs: selected list of adjectives. Used to make Api call, which returns all the adjectives(total of 12, including the selected one)
  // which matches the list given
  COLOR_PALETTE: "color_palette",
  EMAIL: "email",
  MATCHES: "matches",
  REPORT: "report",
  SCREENSHOT: "screenshot",
  SUBMIT_ADJS: "submit_adjs",
  URL: "url",
};

type TCMApiCallJobPostData = {
  ADJS?: "adjs", // adjs: selected list of adjectives. Used to make Api call, which returns all the adjectives(total of 12, including the selected one)
  // which matches the list given
  COLOR_PALETTE?: "color_palette",
  TO_EMAIL?: "to_email",
  MATCHES?: "matches",
  REPORT?: "report",
  SCREENSHOT?: "screenshot",
  SUBMIT_ADJS?: "submit_adjs",
  URL?: "url",
};

type TJobData =
  | $PropertyType<TCMApiCallJobData, "ADJS">
  | $PropertyType<TCMApiCallJobData, "COLOR_PALETTE">
  | $PropertyType<TCMApiCallJobData, "EMAIL">
  | $PropertyType<TCMApiCallJobData, "MATCHES">
  | $PropertyType<TCMApiCallJobData, "REPORT">
  | $PropertyType<TCMApiCallJobData, "SCREENSHOT">
  | $PropertyType<TCMApiCallJobData, "SUBMIT_ADJS">
  | $PropertyType<TCMApiCallJobData, "URL">;

// the path api expects, to get these data back based on a job id
export const CMApiCallJobData: TCMApiCallJobData = {
  ADJS: "adjs",
  COLOR_PALETTE: "color_palette",
  EMAIL: "email",
  MATCHES: "matches", // gives the percent match for the current colors for the url screenshot
  REPORT: "report",
  SCREENSHOT: "screenshot",
  SUBMIT_ADJS: "submit_adjs",
  URL: "url",
};

export const useColorMotiveAPI = (
  asyncFunction: () => Promise<any>,
  immediate: boolean = true,
  runOnInitialLoadOnly: boolean = false,
  onComplete?: ?(response: TResponse) => void = null,
  onError?: ?(error: TResponse) => void = null,
): TApiResponse => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [hasNotRanOnce, setHasNotRanOnce] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const executeApiCall = useCallback(async () => {
    setResponse(null);
    setError(null);
    setIsLoading(true);

    if (hasNotRanOnce) {
      setHasNotRanOnce(false);
    }

    try {
      const response = await asyncFunction();
      setResponse(response);
      onComplete && onComplete(response);
    } catch (error) {
      setError(error);
      onError && onError(error);
    } finally {
      setIsLoading(false);
    }
  }, [asyncFunction, hasNotRanOnce, onComplete]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate || (runOnInitialLoadOnly && hasNotRanOnce)) {
      executeApiCall();
    }
  }, [executeApiCall, hasNotRanOnce, immediate, runOnInitialLoadOnly]);

  return {
    executeApiCall: executeApiCall,
    status: response?.status,
    response: response,
    error: error,
    isLoading: isLoading,
  };
};

export const getCMApiCallJobs = (postData: TCMApiCallJob): Promise<any> => {
  const bodyFormData = new FormData();
  bodyFormData.append("url", postData.url);
  return api.post(Routes.JOBS, bodyFormData);
};

// moving this functionality out of CMApiCallJobData to avoid bloat
export const getAdjectives = (): Promise<any> => {
  return api.get('/adjs');
}

export const getCMApiCallJobData = (
  jobID: string,
  apiDataNeeded: TJobData,
  apiCallMethod: typeof ApiCallMethod = "get",
  postData?: ?TCMApiCallJobPostData = null,
): Promise<any> => {
  if (apiCallMethod === "get") {
    return api.get(`${Routes.JOBS}/${jobID}/${apiDataNeeded}`);
  }

  if (postData === null) {
    return api.post(`${Routes.JOBS}/${jobID}/${apiDataNeeded}`);
  }

  const entries: Array<[TJobData, any]> = typedObjectEntries(postData);

  // if there is post data to be sent to the api, iterate through
  // all the post data we have and append it to the form data
  const bodyFormData = new FormData();
  entries.forEach(([key: string, value: any]) => {
    // if value is an empty array, we skip
    if (Array.isArray(value) && value.length === 0) {
      return;
    }
    bodyFormData.append(key, value);
  });

  return api.post(`${Routes.JOBS}/${jobID}/${apiDataNeeded}`, bodyFormData);
};
