// @flow

const LOCAL_STORAGE_TOKEN = 'cm-job-id';

const get = (token: string = LOCAL_STORAGE_TOKEN) => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(token);
  }
}

const set = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value);
  }
}

export default {
  get,
  set,
}