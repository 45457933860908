// @flow

export type GAEventCategory = "URLEntry" | "Results";

export const GAEventCategories: { [string]: GAEventCategory } = {
  RESULTS: "Results",
  URL_ENTRY: "URLEntry",
};

export type GAEventAction =
  | "button_click"
  | "display"
  | "link_click"
  | "select"
  | "user_input";

export const GAEventActions: { [string]: GAEventAction } = {
  BUTTON_CLICK: "button_click",
  DISPLAY: "display",
  LINK_CLICK: "link_click",
  SELECT: "select",
  USER_INPUT: "user_input",
};

export type GAEventCustomProps = { [string]: string };

export default class GAEventLogger {
  static logEvent(
    category: GAEventCategory,
    action: GAEventAction,
    customProps?: GAEventCustomProps,
  ): void {
    if (window.gtag) {
      // If not null, add additional info to the data being logged
      const additionalInfo: GAEventCustomProps = {
        ...(customProps ? customProps : {}),
      };
      window.gtag("event", action, {
        ...additionalInfo,
        event_category: category,
      });
    }
  }
}
